import BaseOption from '../../../common/base-option-dialog.vue';
export default {
  components: {
    BaseOption
  },
  data() {
    return {
      table: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      warehouseId: 0,
      warehouseType: '',
      warehouseVisible: false,
      formRules: {
        oversea_location_id: [{
          required: true,
          message: '请选择'
        }],
        warehouse_area_id: [{
          required: true,
          message: '请选择'
        }],
        name: [{
          required: true,
          message: '请输入'
        }, {
          pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
          message: '只允许中英文，数字，下划线，中划线'
        }, {
          min: 1,
          max: 20,
          message: '最多只允许输入20位'
        }]
      },
      chooseForm: {}
    };
  },
  mounted() {
    this.getWarehouse();
  },
  methods: {
    async getWarehouse() {
      let params = this.chooseForm;
      params.current_page = this.pagination.current_page;
      params.page_size = this.pagination.page_size;
      //由于公共base-form,故删除多余的参数
      delete params.end_shipping_time;
      delete params.start_shipping_time;
      delete params.year;
      try {
        await this.$store.dispatch('system/base/getWarehouse', {
          params
        });
        let warehouse = this.$store.state.system.base;
        this.table = {
          tableData: warehouse.warehouse,
          tableFields: this.$global.warehouseTableFields
        };
        this.total = warehouse.warehouseLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getWarehouse();
    },
    resetForm() {
      this.pagination.current_page = 1;
      this.pagination.page_size = 10;
      this.chooseForm = {};
      this.getWarehouse();
    },
    changePagination(val) {
      this.pagination = val;
      this.getWarehouse();
    },
    showWarehouseDialog(type, id, warehouse_area_id, oversea_location_id, name) {
      this.warehouseType = type;
      if (type === 'update') {
        this.warehouseId = id;
        this.form.warehouse_area_id = warehouse_area_id;
        this.form.oversea_location_id = oversea_location_id;
        this.form.name = name;
        this.$global.warehouseChooseOptions[0].disabled = true;
        this.$global.warehouseChooseOptions[1].disabled = true;
      } else {
        this.form = {};
        this.$global.warehouseChooseOptions[0].disabled = false;
        this.$global.warehouseChooseOptions[1].disabled = false;
      }
      this.warehouseVisible = true;
    },
    closeWarehouse() {
      this.warehouseVisible = false;
      this.$refs.warehouseForm.$refs.form.resetFields();
    },
    async createWarehouse() {
      try {
        await this.$store.dispatch('system/base/createWarehouse', this.form);
        this.warehouseVisible = false;
        this.$refs.warehouseForm.$refs.form.resetFields();
        this.pagination.current_page = 1;
        localStorage.removeItem('warehouse');
        this.getWarehouse();
      } catch (err) {
        return;
      }
    },
    async updateWarehouse() {
      let body = this.form;
      body.id = this.warehouseId;
      try {
        await this.$store.dispatch('system/base/updateWarehouse', body);
        this.warehouseVisible = false;
        this.$refs.warehouseForm.$refs.form.resetFields();
        localStorage.removeItem('warehouse');
        this.getWarehouse();
      } catch (err) {
        return;
      }
    },
    async submitWarehouse() {
      this.$refs.warehouseForm.$refs.form.validate(valid => {
        if (valid) {
          if (this.warehouseType === 'create') {
            this.createWarehouse();
          } else {
            this.updateWarehouse();
          }
        }
      });
    }
  }
};