import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  class: "select-title"
};
const _hoisted_3 = {
  class: "section-border"
};
const _hoisted_4 = {
  class: "flex-between"
};
const _hoisted_5 = {
  class: "select-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_button = _resolveComponent("el-button");
  const _component_base_form = _resolveComponent("base-form");
  const _component_base_table = _resolveComponent("base-table");
  const _component_base_option = _resolveComponent("base-option");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 筛选条件 ")]), _createVNode(_component_base_form, {
    properties: _ctx.$global.warehouseChooseOptions,
    "base-form": $data.chooseForm,
    inline: true,
    width: "130px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.queryList
    }, {
      default: _withCtx(() => [_createTextVNode(" 查询 ")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.resetForm
    }, {
      default: _withCtx(() => [_createTextVNode(" 重置 ")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }, 8, ["properties", "base-form"])]), _createElementVNode("section", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 仓库列表 ")]), _createVNode(_component_el_button, {
    type: "primary",
    style: {
      "margin-left": "20px"
    },
    onClick: _cache[0] || (_cache[0] = $event => $options.showWarehouseDialog('create'))
  }, {
    default: _withCtx(() => [_createTextVNode(" 新增 ")]),
    _: 1
  })]), _createVNode(_component_base_table, {
    style: {
      "margin-top": "20px"
    },
    table: $data.table,
    pagination: $data.pagination,
    total: $data.total,
    "icon-visible": false,
    "selection-visible": false,
    onChangePagination: $options.changePagination
  }, {
    default: _withCtx(slotProps => [_createVNode(_component_el_button, {
      link: "",
      type: "primary",
      size: "small",
      style: {
        "width": "40px",
        "margin-left": "20px"
      },
      onClick: $event => $options.showWarehouseDialog('update', slotProps.row.id, slotProps.row.warehouse_area_id, slotProps.row.oversea_location_id, slotProps.row.warehouse_name)
    }, {
      default: _withCtx(() => [_createTextVNode(" 修改 ")]),
      _: 2
    }, 1032, ["onClick"])]),
    _: 1
  }, 8, ["table", "pagination", "total", "onChangePagination"]), $data.warehouseVisible ? (_openBlock(), _createBlock(_component_base_option, {
    key: 0,
    modelValue: $data.warehouseVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.warehouseVisible = $event),
    title: $data.warehouseType === 'create' ? '新增仓库' : '修改仓库',
    width: "25%",
    onCloseDialog: $options.closeWarehouse
  }, {
    default: _withCtx(() => [_createVNode(_component_base_form, {
      ref: "warehouseForm",
      properties: _ctx.$global.warehouseChooseOptions,
      "base-form": $data.form,
      "base-rules": $data.formRules,
      inline: false,
      width: "130px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        onClick: $options.closeWarehouse
      }, {
        default: _withCtx(() => [_createTextVNode(" 取消 ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.submitWarehouse
      }, {
        default: _withCtx(() => [_createTextVNode(" 确定 ")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    }, 8, ["properties", "base-form", "base-rules"])]),
    _: 1
  }, 8, ["modelValue", "title", "onCloseDialog"])) : _createCommentVNode("", true)])]);
}